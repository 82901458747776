import { getLinkHref } from 'lib/utils/helpers';
import { SiteLink } from '.';

const seperator = <span className="ml-[4px]">›</span>;

const Breadcrumbs = ({ items, currentLabel, homeLabel, className }) => {
  const home = homeLabel ? <SiteLink href="/">{homeLabel}</SiteLink> : false;
  const links = items.filter(Boolean).map(data => {
    if (!data) return null;
    const { text, link, externalLink } = data;
    return (
      <SiteLink key={text} href={getLinkHref(link)} external={externalLink}>
        {text}
      </SiteLink>
    );
  });
  const current = currentLabel || false;

  const crumbs = [home, ...links, current].filter(Boolean);

  return (
    <ol
      className={`color-blue flex items-center justify-center gap-[4px] bg-[#E7EDF1] p-[11px] font-nimbus-sans-d text-[12px] ${
        className || ''
      }`}
    >
      {crumbs.map((element, index, { length }) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className={currentLabel ? 'last:font-bold' : null}>
          {element}
          {index !== length - 1 && seperator}
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumbs;
