import { useRef } from 'react';
import dynamic from 'next/dynamic';
import { pageSectionsCollectionFragment } from 'lib/contentful/fragments';
import { SECTION_FRAGMENTS } from 'lib/contentful/fragments/sections';

const Article = dynamic(() => import('components/sections/article'));
const Articles = dynamic(() => import('components/sections/articles'));
const Banners = dynamic(() => import('components/sections/banners'));
const Collection = dynamic(() => import('components/sections/collection'));
const Hero = dynamic(() => import('./hero'));
const Highlight = dynamic(() => import('components/sections/highlight'));
const Parallax = dynamic(() => import('components/sections/parallax'));
const SeasonPreview = dynamic(() => import('components/sections/season-preview'));
const Split = dynamic(() => import('components/sections/split'));
const Story = dynamic(() => import('components/sections/story'));
const SubCampaign = dynamic(() => import('components/sections/sub-campaign'));
const ThreeColumnBanners = dynamic(() => import('components/sections/ThreeColumnBanners'));
const Usps = dynamic(() => import('components/sections/usps'));
const Newsletter = dynamic(() => import('./newsletter'));
const ShopTheLook = dynamic(() => import('components/sections/shopTheLook'));

const SECTION_COMPONENTS = {
  Article,
  Articles,
  Banners,
  Collection,
  Hero,
  Highlight,
  Parallax,
  SeasonPreview,
  Split,
  Story,
  SubCampaign,
  ThreeColumnBanners,
  Usps,
  Newsletter,
  ShopTheLook,
};

export const CORE_SECTIONS_FRAGMENT = pageSectionsCollectionFragment(
  Object.keys(SECTION_FRAGMENTS)
);

const getComponentByType = type => SECTION_COMPONENTS[type];

const getSectionType = section => section?.type?.replace('Section', '');

const isSectionSupported = section => !!getComponentByType(getSectionType(section));

const SectionWithViewTracking = ({ children, num, section: { type, name } }) => {
  const ref = useRef();
  return <section ref={ref}>{children}</section>;
};

// eslint-disable-next-line no-underscore-dangle
const renameTypename = ({ __typename, ...entry }) => ({ ...entry, type: __typename || null });

const Sections = ({ data }) => (
  <>
    {data &&
      (data.items || [])
        .filter(Boolean)
        .map(renameTypename)
        .filter(isSectionSupported)
        .map((section, idx) => {
          const sectionType = getSectionType(section);
          const Section = getComponentByType(sectionType);

          return (
            <SectionWithViewTracking num={idx + 1} section={section} key={section.sys.id}>
              <Section data={section} index={idx} validateProps />
            </SectionWithViewTracking>
          );
        })}
  </>
);

export default Sections;
