const { IMAGE_QUERY_FIELDS, NEWSLETTER_SIGN_UP_FIELDS } = require('./shared');

const LINK_INLINE_FRAGMENTS = `
  ... on PageGeneral {
    __typename
    slug
  }
  ... on PageProduct {
    __typename
    slug
  }
  ... on PageCollection {
    __typename
    slug
  }
  ... on PageProductsListing {
    __typename
    slug
  }
`;

const LINK_QUERY_FIELDS = `
    name
    text
    link {
      ${LINK_INLINE_FRAGMENTS}
    }
    externalLink
    query
`;

const RICH_TEXT_LINKS_FIELDS = `
  links {
    entries {
      hyperlink {
        sys {
          id
        }
        ${LINK_INLINE_FRAGMENTS}
      }
    }
  }
`;

const SECTION_ARTICLE_FRAGMENT = `
... on SectionArticle {
  name
  __typename
  sys {
    id
  }
  article {
    title
    article {
      json
      ${RICH_TEXT_LINKS_FIELDS}
    }
    image {
      url
      width
      height
    }
    mobileImage {
      url
      width
      height
    }
    submenusCollection(skip: 0, limit: 10) {
      items {
        ... on ComponentArticle {
          __typename
          name
          title
          article {
            json
            ${RICH_TEXT_LINKS_FIELDS}
          }
        }
      }
    }
    vimeoUrl
    mobileVimeoUrl
  }
  showImageFullScreen
  showImageOnLeft
  showBlueBackground
  articleColumnWidth
  paddingTop
  paddingBottom
  sectionId
  linksCollection(limit: 30) {
    items {
      name
      ${LINK_QUERY_FIELDS}
    }
  }
  buttonsCollection (limit: 6) {
    items {
      ... on ComponentImageLink {
        __typename
        sys {
          id
        }
        name
        link {
          ${LINK_QUERY_FIELDS}
        }
        image {
          url
          width
          height
        }
      }
      ... on ComponentButton {
        __typename
        sys {
          id
        }
        link {
          ${LINK_QUERY_FIELDS}
        }
        style
      }
    }
  }
}
`;

const SECTION_ARTICLES_FRAGMENT = `
  ... on SectionArticles {
    name
    __typename
    sys {
      id
    }
    showAsCarousel
    title
    layout
    articlesCollection(limit: 20) {
      items {
        sys {
          id
        }
        title
        article {
          json
          ${RICH_TEXT_LINKS_FIELDS}
        }
        image {
          url
          width
          height
        }
        mobileImage {
          url
          width
          height
        }
      }
    }
  }
`;

const SECTION_THREE_COLUMN_BANNERS_FRAGMENT = `
... on SectionThreeColumnBanners {
  name
  __typename
  sys {
    id
  }
  withGap
  bannersCollection(limit: 3) {
    items {
      sys {
        id
      }
      image {
        url
        width
        height
      }
      mobileImage {
        url
        width
        height
      }
      hoverImage {
        url
        width
        height
      }
      mobileHoverImage {
        url
        width
        height
      }
      vimeoUrl
      mobileVimeoUrl
      title
      subtitle
      link {
        ${LINK_QUERY_FIELDS}
      }
      fullHeight
    }
  }
}
`;

const SECTION_BANNERS_FRAGMENT = `
... on SectionBanners {
  name
  __typename
  sys {
    id
  }
  showFrame
  showAsCarousel
  verticalMargins
  animated
  bannersCollection(limit: 20) {
    items {
      sys {
        id
      }
      image {
        url
        width
        height
      }
      mobileImage {
        url
        width
        height
      }
      hoverImage {
        url
        width
        height
      }
      mobileHoverImage {
        url
        width
        height
      }
      vimeoUrl
      mobileVimeoUrl
      title
      subtitle
      link {
        ${LINK_QUERY_FIELDS}
      }
      fullHeight
    }
  }
}
`;

const SECTION_COLLECTION_FRAGMENT = `
... on SectionCollection {
  name
  __typename
  sys {
    id
  }
  title
  showAsCarousel
  productsCollection {
    items {
      __typename
      ... on PageProduct {
        sys {
          id
        }
        productTitle
        shopifyIdEu
        shopifyIdGb
        shopifyIdUs
        slug
        thumbnailImage {
          url
          width
          height
        }
      }
    }
  }
  link {
    ${LINK_QUERY_FIELDS}
  }
}
`;

const SECTION_HERO_FRAGMENT = `
... on SectionHero {
  name
  __typename
  sys {
    id
  }
  name
  image {
    url
    width
    height
  }
  vimeoUrl
  vimeoVideoPlaceholder{
    url
  }
  mobileImage {
    url
    width
    height
  }
  mobileVimeoUrl
  mobileVimeoVideoPlaceholder{
    url
  }
  showScissors
  showFrame
  topHeading
  mainHeading
  bold
  showWhiteRectangle
  text
  link {
    ${LINK_QUERY_FIELDS}
  }
  buttonsCollection {
    items {
      ... on ComponentButton {
        style
        link {
          ${LINK_QUERY_FIELDS}
        }
      }
    }
  }
  titleType
  date
  dateUnits
  height
  animated
  linkStyle
}
`;

const SECTION_HIGHLIGHT_FRAGMENT = `
... on SectionHighlight {
  name
  __typename
  sys {
    id
  }
  image {
    url
    width
    height
  }
  showScissors
  bold
  title
  titleType
  smallBoldTitle
  subtitle
  subtitleRichtext{
    json
    ${RICH_TEXT_LINKS_FIELDS}
  }
  typography
  link {
    ${LINK_QUERY_FIELDS}
  }
  showWhiteSpace
}
`;

const SECTION_PARALLAX_FRAGMENT = `
... on SectionParallax {
  name
  __typename
  sys {
    id
  }
  mode
  signature {
    url
    width
    height
  }
  subtitle
  title
  singleMedia {
    contentType
    url
    width
    height
  }
  multiMediaCollection {
    items {
      contentType
      url
      width
      height
    }
  }
  showLogo
}
`;

const SECTION_SEASON_PREVIEW_FRAGMENT = `
... on SectionSeasonPreview {
  name
  __typename
  sys {
    id
  }
  article {
    title
    article {
      json
      ${RICH_TEXT_LINKS_FIELDS}
    }
  }
  showArticleOnLeft
  bannersCollection {
    items {
      image {
        url
        width
        height
      }
      mobileImage {
        url
        width
        height
      }
      title
      subtitle
      link {
        ${LINK_QUERY_FIELDS}
      }
      fullWidth
    }
  }
}
`;

const SECTION_SPLIT_FRAGMENT = `
... on SectionSplit {
  name
  __typename
  sys {
    id
  }
  backgroundImage {
    url
  }
  title
  heroTitle
  heroImage {
    url
    width
    height
  }
  vimeoUrl
  description
  link {
    ${LINK_QUERY_FIELDS}
  }
}
`;

const SECTION_STORY_FRAGMENT = `
... on SectionStory {
  name
  __typename
  sys {
    id
  }
  name
  hasImageBackground
  article {
    title
    article {
      json
      ${RICH_TEXT_LINKS_FIELDS}
    }
    image {
      url
      width
      height
    }
    mobileImage {
      url
      width
      height
    }
  }
}
`;

const SECTION_SUB_CAMPAIGN_FRAGMENT = `
... on SectionSubCampaign {
  name
  __typename
  sys {
    id
  }
  title
  backgroundImage {
    url
  }
  isWhiteColor
  buttonsCollection {
    items {
      ... on ComponentButton {
        style
        link {
          ${LINK_QUERY_FIELDS}
        }
      }
    }
  }
}
`;

const SECTION_SHOP_THE_LOOK = `
  ... on SectionShopTheLook {
    name
    __typename
    sys {
      id
    }
    thumbnailsCollection (limit: 20) {
      items {
        __typename
        sys {
          id
        }
        name
        thumbnailBackground {
          ... on ComponentMedia {
            __typename
            sys {
              id
            }
            image {
              ${IMAGE_QUERY_FIELDS}
            }
            vimeoUrl
          }
        }
        thumbnailLink {
          __typename
          name
          ${LINK_QUERY_FIELDS}
        }
        tagPointsCollection(limit: 10) {
          items {
            __typename
            sys {
              id
            }
            positionX
            positionY
            product {
              productTitle
              slug
            }
          }
        }
      }
    }
  }
`;

const SECTION_FRAGMENTS_OBJ = {
  SectionArticle: SECTION_ARTICLE_FRAGMENT,
  SectionArticles: SECTION_ARTICLES_FRAGMENT,
  SectionBanners: SECTION_BANNERS_FRAGMENT,
  SectionThreeColumnBanners: SECTION_THREE_COLUMN_BANNERS_FRAGMENT,
  SectionCollection: SECTION_COLLECTION_FRAGMENT,
  SectionHero: SECTION_HERO_FRAGMENT,
  SectionHighlight: SECTION_HIGHLIGHT_FRAGMENT,
  SectionParallax: SECTION_PARALLAX_FRAGMENT,
  SectionSeasonPreview: SECTION_SEASON_PREVIEW_FRAGMENT,
  SectionSplit: SECTION_SPLIT_FRAGMENT,
  SectionStory: SECTION_STORY_FRAGMENT,
  SectionSubCampaign: SECTION_SUB_CAMPAIGN_FRAGMENT,
  SectionUsps: `
    ... on SectionUsps {
      __typename
      sys {
        id
      }
      name
      uspsCollection {
        items {
          title
          text {
            json
            ${RICH_TEXT_LINKS_FIELDS}
          }
          image {
            url
            width
            height
          }
          link {
            ${LINK_QUERY_FIELDS}
          }
        }
      }
    }
  `,
  SectionNewsletter: `
  ... on SectionNewsletter {
    __typename
      sys {
        id
      }
      name
      title
      image {
        ${IMAGE_QUERY_FIELDS}
      }
      imageDesktop {
        ${IMAGE_QUERY_FIELDS}
      }
      buttonText
      newsletterSignUp {
        ${NEWSLETTER_SIGN_UP_FIELDS}
      }
  }`,
  SectionShopTheLook: SECTION_SHOP_THE_LOOK,
};

exports.SECTION_FRAGMENTS = SECTION_FRAGMENTS_OBJ;

exports.SECTION_TITLES_ARRAY = Object.keys(SECTION_FRAGMENTS_OBJ);
