/* 
  Highstreet app banner meta tags 
    - Deeplink docs: https://t6622427.p.clickup-attachments.com/t6622427/005afa13-a124-4887-8fd3-0ff7e1b7151c/How%20to%20construct%20a%20Deeplink%20URL_%20_%20Highstreet%20Mobile.pdf?view=open
*/

import Head from 'next/head';

const HighstreetAppMetaUrl = ({ url }) => {
  return (
    <Head>
      <meta property="al:ios:url" content={`denham://${url}`} />
    </Head>
  );
};

export default HighstreetAppMetaUrl;
