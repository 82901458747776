import { useStoreContext } from 'contexts';
import Head from 'next/head';
import { useRouter } from 'next/router';

const siteName = process.env.NEXT_PUBLIC_SITENAME;
const siteUrl = process.env.NEXT_PUBLIC_SITEURL;

export const SEO_FRAGMENT = `
  seo {
    title
    description
    image {
      url
    }
  }
`;

const SEO = ({ seo = {} }) => {
  const { locale } = useStoreContext();
  const router = useRouter();

  const pageUrl = `${siteUrl}${router.asPath}`;

  const {
    localeSlugs = {},
    title = siteName,
    description = 'Shop the new collection of jeans, t-shirts, sweats and jackets. The truth is in the details.',
    image,
    ogImage = image?.url || '/images/og_image.jpg',
  } = seo;

  return (
    <Head>
      <title>{title}</title>
      <meta httpEquiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="description" content={description} />
      <meta name="image" content={ogImage} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={locale} />
      <link rel="canonical" href={pageUrl} />
      {Object.entries(localeSlugs)
        .filter(([slugLocale]) => slugLocale !== locale)
        .map(([slugLocale, slug]) => (
          <link
            key={slugLocale}
            rel="alternate"
            hrefLang={slugLocale}
            href={`${siteUrl}/${slug}`}
          />
        ))}
    </Head>
  );
};

export default SEO;
